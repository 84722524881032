
import fancybox from '@fancyapps/fancybox';
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
import LazyLoad from "vanilla-lazyload";
import Mmenu from "mmenu-js";
import select2 from "select2";
// import intlTelInput from 'intl-tel-input';
// import "inputmask/dist/inputmask/inputmask.numeric.extensions";
import "jquery-mask-plugin";



let miniShop2;
global.miniShop2 = miniShop2;
 
global.jQuery = $;
global.$ = $;
global.LazyLoad = LazyLoad;
// global.intlTelInput = intlTelInput;

$(".form-phone input").mask("380999999999");

// const input = document.querySelector(".form-phone");
// intlTelInput(input, {
//   onlyCountries: ["ua", "ru"],
//   // utilsScript: "/js/utils.js" // just for formatting/placeholders etc
// });

$('.select').select2({
  theme: 'bootstrap4',
});


// import 'swiper/swiper-bundle.css';
// import sayHello from './lib/sayHello';

$().fancybox({
    selector : '.imglist a:visible'
});

$('[data-fancybox="gallery"]').fancybox({
  buttons: [
    "zoom",
    // "share",
    // "slideShow",
    // "fullScreen",
    // "download",
    // "thumbs",
    "close"
  ],
    beforeShow( instance, slide ) {

      console.info( instance.currIndex );

    }
});

Swiper.use([Navigation, Pagination, Scrollbar]);

const mySwiper = new Swiper('.swiper-container', {
  // speed: 0,
  slidesPerView: 1,
  // freeMode: true,
  threshold: 20,
  lazy: {
    loadPrevNext: true,
  },
  observeParents: true,
 

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});

function logElementEvent(eventName) {
  console.log(eventName);

  $(".btn-print").addClass('loaded_all');
  // if (print === true) {window.print();}
  // if ($(".btn-print").hasClass('loaded_all')) {
  //   window.print();
  // }

}

const myLazyLoad = new LazyLoad({
  "callback_finish" : function () {
    logElementEvent("FINISHED", document.documentElement);
  }
});

myLazyLoad.update();

$(".btn-print").on("click", function () {
  // const eventName = myLazyLoad.loadAll();
  
  if ($(this).hasClass('loaded_all')) {
    window.print();
  } else {
    myLazyLoad.loadAll();
  }
  // logElementEvent("FINISHED", document.documentElement);
});




// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
})()


const $burgerBtn = $(".toggle-mnu");
// $(".toggle-mnu").click(function() {
//   $(this).toggleClass("on");
//   $(".toggled_block").slideToggle();
//   return false;
// });

document.addEventListener(
  "DOMContentLoaded", () => {
    // Fire the plugin
    const menu = new Mmenu( "#my-menu" , {
      wrappers: ["bootstrap"],
      navbar: {
        title: "Меню"
      },
      extensions: [
      "border-none",
      "pagedim-black",
      "theme-white"
      ],
      "counters": true,
      // navbars: [
      //   {
      //     "content": [
      //       "breadcrumbs",
      //       "close"
      //     ]
      //   }
      // ],
      hooks: {
        "openPanel:start": ( panel ) => {
          console.log( `Started opening pane: ${panel.id}` );
          $('#my-menu').prop('hidden', false)
        },
        "open:after": ( panel ) => {
          $burgerBtn.addClass('on')
        },
        "close:after": ( panel ) => {
          $burgerBtn.removeClass('on')
        }
      }
    });


    // Get the API
    const api = menu.API;

    // Invoke a method
    const panel = document.querySelector( "#my-panel" );
    api.openPanel( panel );
  }
);
